import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import StyledSectionBox from "../components/sectionBox/index"
import CertificatesGrid from "../components/certificatesGrid/index"
import { StyledContainer } from "../components/containers/index"

const CertificatesPageZhTw = ({
  location,
  data: {
    background,
    images,
    isoCH,
    isoEN,
    iatf1CH,
    iatf2CH,
    iatf1EN,
    iatf2EN,
    sr1,
    sr2,
    sr3,
    sr4,
    sr5,
    sr6,
    sr7,
    sr8,
    sr9,
    sr10,
    sr11,
    sr12,
    sr13,
    sr14,
  },
}) => {
  const isoCertificates = [isoCH, isoEN]
  const iatfCertificates = [iatf1CH, iatf2CH, iatf1EN, iatf2EN]
  const srCertificates = [
    sr1,
    sr2,
    sr3,
    sr4,
    sr5,
    sr6,
    sr7,
    sr8,
    sr9,
    sr10,
    sr11,
    sr12,
    sr13,
    sr14,
  ]
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="zh-tw"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="certificates"
      />
      <StyledContainer>
        <h3>ISO 9001:2015</h3>
      </StyledContainer>
      <CertificatesGrid certificates={isoCertificates} />
      <StyledContainer>
        <h3 style={{ marginTop: 64 }}>IATF 16949</h3>
      </StyledContainer>
      <CertificatesGrid certificates={iatfCertificates} />
      <StyledContainer>
        <h3 style={{ marginTop: 64 }}>
          <FormattedMessage id="sewageReport" />
        </h3>
      </StyledContainer>
      <CertificatesGrid certificates={srCertificates} />
    </Layout>
  )
}

export const query = graphql`
  {
    background: file(
      relativePath: { eq: "certificates/certificates-background.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    isoCH: file(relativePath: { eq: "certificates/iso-ch-certificate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    isoEN: file(relativePath: { eq: "certificates/iso-en-certificate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iatf1CH: file(
      relativePath: { eq: "certificates/iatf1-ch-certificate.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iatf2CH: file(
      relativePath: { eq: "certificates/iatf2-ch-certificate.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iatf1EN: file(
      relativePath: { eq: "certificates/iatf1-en-certificate.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iatf2EN: file(
      relativePath: { eq: "certificates/iatf2-en-certificate.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr1: file(relativePath: { eq: "certificates/sr1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr2: file(relativePath: { eq: "certificates/sr2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr3: file(relativePath: { eq: "certificates/sr3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr4: file(relativePath: { eq: "certificates/sr4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr5: file(relativePath: { eq: "certificates/sr5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr6: file(relativePath: { eq: "certificates/sr6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr7: file(relativePath: { eq: "certificates/sr7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr8: file(relativePath: { eq: "certificates/sr8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr9: file(relativePath: { eq: "certificates/sr9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr10: file(relativePath: { eq: "certificates/sr10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr11: file(relativePath: { eq: "certificates/sr11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr12: file(relativePath: { eq: "certificates/sr12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr13: file(relativePath: { eq: "certificates/sr13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sr14: file(relativePath: { eq: "certificates/sr14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CertificatesPageZhTw
